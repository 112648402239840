/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 28, 2020 */



@font-face {
    font-family: 'sound_bubble_demoregular';
    src: url('sound_bubble_demo-webfont.woff2') format('woff2'),
         url('sound_bubble_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}