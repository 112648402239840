@media only screen and (min-width : 1200px) {
  /*  .container{
        max-width:1170px;
    }*/
}
/*============================================================  
                    Mobile First Method  
 =============================================================*/
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1580px) {

}
@media only screen and (max-width : 1199px) {
    .home-featured-courses .boxes .slick-prev::before, .home-featured-courses .boxes .slick-next::before {font-size:50px;}
    .home-featured-courses .boxes .slick-next{right:-10px;}
    .home-featured-courses .boxes .slick-prev{left:-10px;}
}
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

}
/* Small Devices, Tablets */
@media only screen and (max-width : 991px) {
	.services-section .latest-courses .boxes .box .img div{height:130px;display:flex;}
	.services-section .latest-courses .boxes .box .img{height:150px;}
	.services-section .latest-courses .boxes .box .content h2{font-size:1.5rem;}
	.services-section .latest-courses .boxes .box .content p{font-size:11px;margin-top:5px !important;max-height:unset;}
	.banner-section .row {
		flex-flow: column;
	}
	.banner-section {
		min-height: 100vh;
		height: auto;padding-top:16% !important;
	}
	.banner-section .row .col-md-7 {
		width: 100%;
		max-width: 100%;
	}
	.banner-section{padding-bottom:0px !important;}
}
@media only screen and (max-width : 850px) {
	.services-section #students .student-list .inner, .sugested-teacher .box{width:100%;}
	.teacher-page .teacher-top-sectionn .inner{top:0;padding:0px !important;}
	.teacher-page .teacher-top-sectionn{padding:20% 0px 30px 0px;}
	.teacher-page .teacher-top-sectionn .inner .img{width:150px;height:150px;}
	
}
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 767px) {
	.banner-section .row{width:100%;}
	header.site-header .navbar-toggler {
		border: unset;
		background: #fbac18;
		color: #fff;
		border-radius: 3px;
		padding: 6px 12px;
		font-size: 22px;
	}
	header.site-header nav ul li.logo {
		display: none;
	}
	header.site-header .navbar-nav,
	header.site-header .navbar-nav div {
		flex-flow: column;
	}
	header.site-header nav ul li {
		margin: 0px 0px;
		width: 100%;
	}
	header.site-header .navbar-nav {
		background: rgb(251, 172, 24);
		padding: 5px 0px;
		z-index: 1234555 !important;
		position: relative;
		align-items: flex-start !important;
	}
	header.site-header nav ul li a {
		display: block;
		border-bottom: 1px solid #fff;
		padding: 8px 20px !important;transition:0.3s all;
	}
	header.site-header nav ul li a:hover {
		color: #fff;
		background: #262262;
		border-color: #262262;
	}
	header.site-header nav ul li .menu-button{border-radius:0px;padding: 8px 20px !important;}
	header.site-header nav ul li .menu-button:hover{background: #262262;
		border-color: #262262;}
	.site-header .header-social li a {
		border: unset;
		padding: 0 !important;
	}
	.site-header .header-social li a:hover{background:transparent;color:#fbac18;}
	header.site-header .navbar-nav div {
		align-items: flex-start !important;
		width: 100%;
	}
	header.site-header .navbar-nav.ml-auto {
		align-items: flex-start !important;
		margin-top: 20px;
	}
	header.site-header .navbar-nav.ml-auto li {
		margin-bottom: 10px;
	}
	.site-header .mobile-logo {
		display: block !important;
	}
	.site-header .mobile-logo img {
		max-width: 60px !important;
		width: 100%;
	}
	.banner-section {
		height: auto;padding-top:130px !important;
	}
	.banner-section .col-md-4 {
		display: none;
	}
	.subscribe-form .container {
		padding: 0;
		margin-top: 20px;
	}
	.banner-section h1 {
		font-size: 2rem;
	}
	.banner-section h4 {
		font-size: 20px;
	}
	.service-boxes .box{width:48%;}
	.services-section ul.nav-tabs li a{font-size: 14px;padding: 10px 0px;}
	.teacher-section{height:auto;padding:40px 0px;}
	.teacher-section .container{position:unset;}
	.teacher-section .container .row img {
		max-width: 200px;
		margin-bottom: 20px;
	}
	.teacher-section .container .row h2{font-size:1.6rem;}
	.teacher-section .container .row p{font-size:16px;}
	.teacher-section .container .row p br{display:none;}
	.faq-section .card-header .card-link{padding-bottom:1.5rem;margin-bottom:1rem;}
	.faq-section .card-header .card-link::before {padding: 6px 9px;height: 30px;width: 30px;}
	.faq-section .card-body{font-size:0.8rem;}
	.signup-page .inner h2, .cpod-title{padding:24px 37px 24px 10px;}
	.pod-page.pt-5 {
		padding-top: 0 !important;
	}
	.pod-page .banner-section {
		padding: 0 !important;
		text-align: left;
	}
	.pod-page .banner-section .inner {
		padding: 30px 20px !important;
	}
	.review-box .inner {
		flex-flow: column;
		align-items: flex-start !important;
	}
	.review-box .right {
		margin-top: 15px;
	}
	.services-section{border-top:0px;}
	.services-section .latest-courses .boxes .box {
		width: 47%;
		position: relative;
	}
	.services-section .latest-courses h1,
	.faq-section h2,.home-services-section h1, .home-featured-courses h1, .home-about-section h1
	{font-size:2rem;}
	.signup-page {
		padding-top: 110px;
	}
	.signup-page .inner h2, .cpod-title,
	.pod-page .banner-section .inner h4,
	.sugested-teacher h4, .join-pod h2{font-size:2rem;}
	.create-pod .select {
		width: 100%;
	}
	.create-pod .select::before {
		right: 10px;
		top: 16px;
	}
	.home-services-section h1 + p {
		font-size: 1rem;
	}
	.home-services-section {
		margin: 0px !important;
	}
	.home-about-section {
		margin: 0px !important;
	}
	.home-about-section a {
		margin-bottom: 20px;
	}
	.home-testimonial .container {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.home-testimonial .testimonial-inner {
		padding: 40px 2%;
	}
	.home-testimonial .boxes .slick-prev {
		left: -1%;
	}
	.home-testimonial .boxes .slick-next {
		right: -1%;
	}
	.home-testimonial .boxes .slick-prev::before, .home-testimonial .boxes .slick-next::before {font-size:50px;}
	.home-testimonial .boxes .box{padding:30px 20px;}
	.home-testimonial .boxes .box .img{width: 80px;
height: 80px;margin-top:-70px;}
	.home-testimonial .boxes .box p{font-size:12px;margin-top:10px;}
	.site-footer ul.links li a{margin-bottom:10px;}
	.teacher-page .teacher-top-sectionn .inner {
		flex-flow: column;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
	.teacher-page .teacher-top-sectionn .inner .right {
		padding-bottom: 0 !important;
		margin-top: 20px;
	}
	.teacher-page .teacher-bottom-section .container {
		flex-flow: column;
	}
	.teacher-page .teacher-bottom-section .container .left {
		width: 100%;
	}
	.teacher-page .teacher-bottom-section .container .right {
		width: 100%;
	}
	.teacher-page .teacher-bottom-section {
		margin-top: 0px !important;
	}
}
/* Custom, iPhone Retina */ 
@media only screen and (max-width : 667px) {
	.service-boxes .box{width:100%;}
	div.proceed-notification, div.create-pod {
		padding-left: 20px;
		padding-right: 20px;
	}
	div.create-pod form .d-flex {
		flex-flow: column;
		text-align: left;
		align-items: flex-start !important;
	}
	div.create-pod form .d-flex select {
		width: 100%;
		margin-top: 10px;
		max-width: 100%;
	}
	.join-pod-page .questions .content h6 {
		font-size: 13px;
	}
	.services-section #students .student-list .inner, .sugested-teacher .box {
		flex-flow: column;
		align-items: flex-start !important;
	}
	.services-section #students .student-list .inner .right a, .sugested-teacher .box .right a {
		margin-top: 15px;
	}
	.signup-page.parent-signup .inner form .input {
		width: 100%;
	}
	.banner-section {
		padding: 130px 20px 0px !important;
	}
	.subscribe-form .inner form label {
		width: 70%;
		text-align: left;
	}
	/*.subscribe-form .inner form div {
		flex-wrap: nowrap !important;
	}*/
	.subscribe-form .inner form button {
		margin-left: 10px !important;
		padding: 13px 0px;
		width: 30%;
	}
}
@media only screen and (max-width : 480px) {
	.services-section .latest-courses .boxes .box {
		width: 100%;
	}
	.teacher-page .teacher-top-sectionn {
		padding: 100px 0px 30px 0px;
	}
	.teacher-page .teacher-top-sectionn .inner .left {
		flex-flow: column;
		width: 100%;
	}
	.teacher-page .teacher-top-sectionn .inner .left .content {
		padding-top: 20px !important;
		padding-left: 0 !important;
	}
}