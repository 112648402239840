@media only screen and (min-width : 1200px) {
  /*  .container{
        max-width:1170px;
    }*/
}
/*============================================================  
                    Mobile First Method  
 =============================================================*/
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1580px) {

}
@media only screen and (max-width : 1199px) {
    
}
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {

}
/* Small Devices, Tablets */
@media only screen and (max-width : 991px) {
  .clv-dasboard .main-sidebar {
      position: fixed;
      left: -100%;
      right: 0;
      top: 80px;
      bottom: 0;
      border-top: 1px solid #b23885;
      padding-top: 12px;
      z-index: 435675434353;
      height: 86vh;
      min-height: 86vh;transition:0.6s all;
    }
    .clv-dasboard .main-sidebar.active{left:0;}
    .dashboard-right-section {
      padding: 0;
    }
    .dashboard-profilebar {
      padding-left: 15px;justify-content:space-between;
    }
    .dashboard-profilebar .open-menu{display:inline-block;}
    .view-submission {
      flex-flow: column;
      width: 100%;
    }
    .view-submission .left, .view-submission .right {
      width: 100% !important;
    }
    .view-submission .right{margin-top:20px;}
    .nadd-file{max-width:250px;width:100%;}
    .chs-section .left .inrbox{padding:0px;}
    .chs-section .right {
      margin-top: 20px;
    }
}
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 850px) {
  .mtable {
      overflow-x: scroll;
    }
    .mtable table {
      border-collapse: collapse;
      border-spacing: 0;
      min-width: 800px !important;
    }
    .card-body.monitoring-alert .box{width:49%;margin-bottom:10px;}
}
@media only screen and (max-width : 767px) {
  .clv-pagination ul li a{font-size:22px;}
  .teacher-home-page > .d-flex,
  .teacher-home-page .report-total-student > .d-flex {
    flex-flow: column;
  }
  .teacher-home-page > .d-flex .w-50 {
    width: 100% !important;margin-bottom:20px;
  }
  .teacher-home-page > .d-flex .w-50 #totalPOD {
    max-width: 100% !important;
    width: 100% !important;
  }
  .teacher-home-page .report-total-student > .d-flex .w-50 {
    width: 100% !important;margin-bottom:20px;
  }
  .teacher-home-page .report-total-student .account-student {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .teacher-home-page .report-total-student .account-student .border {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  .allow-broadcast.teacher .pod-camera {
    margin-bottom: 30px;
  }
  .add-q {
    flex-wrap: wrap;
  }
  .add-q label, .add-q div,
  .add-q div .d-flex select {
    width: 100% !important;
  }
  .add-q div .d-flex {
    flex-wrap: wrap;
  }
  .add-q div .d-flex select{margin:4px 0px !important;}
  #question.box .mcq .question-inner input {
    width: 100%;margin-right:0px !important;
  }
  #question.box .mcq .question-inner .option {
    flex-wrap: wrap;
  }
}
/* Custom, iPhone Retina */ 
@media only screen and (max-width : 667px) {
  div.create-pod,
  div.create-pod .container{padding:0px;}
  .competition-boxes .box {
    width: 100%;
  }
}
@media only screen and (max-width : 640px) {
  .dataTables_length label{margin-bottom:0px !important;}
  .dataTables_filter label {
    width: 100%;text-align: left;
  }
  .dataTables_filter label input {
    width: 99%;
  }
  .inner-header {
    margin-bottom: 0px !important;
    margin-top: 10px;
  }
  .inner-header h3 {
    font-size: 2rem;
  }
  .vpod-body .vpod-nav ul{flex-wrap:wrap;}
  .vpod-body .vpod-nav ul li {
    margin-bottom: 10px;
  }
  .view-submission .left .box-inner {
    flex-flow: column;
  }
  .view-submission .left .box-inner .date {
    width: 100%;
    margin-bottom: 10px;
  }
  .inner-header {
    flex-wrap: wrap;
  }
  .student-detail .left > div > div {
    width: 100% !important;
  }
}

@media only screen and (max-width : 580px) {
  .pending-pod-detail .col-md-8 .border {
    width: 100% !important;
  }
  .allow-broadcast.teacher .pod-camera .img .min-camera {
    top: 10px;
  }
  .allow-broadcast.teacher .pod-camera > .img{height:300px;}
  .allow-broadcast.teacher .pod-camera .img ul li a{width: 40px;height: 40px;}
  .dviewbox{width:100%;}
  .mobile-fullw .border {
    width: 100% !important;
    margin-top: 0px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width : 435px) {
  .dashboard-profilebar {
    top: 44px;
  }
  .card-body.monitoring-alert .box{width:100%;}
}