/*
--------------------------------------------------------* 
[Main Stylesheet ]

    Name         : 

    Version      :  1.0

    Author       :  Abdullah
--------------------------------------------------------
   TABLE OF CONTENTS:
========================================================
1 ) Google or local Font Import
2 ) Helping Classes
3 ) Header 
        -( a ) Logo
        -( b ) Menu
4 ) Home page
        -( a ) Story of Glory
        -( b ) What Can I Do
        -( c ) Some Facts
5 ) About Page
        -( a ) Education Section
        -( b ) Experience Section
        -( c ) Skills Section
6 ) Single Page
        -( a ) Portfolio Section
        -( b ) Testimonial Section
7 ) Contact Page
        -( a )  Contact Form
        -( b )  Contact Details
8 ) Footer
--------------------------------------------------------*/
/*      Reset CSS                 */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,
label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,
hgroup,menu,nav,output,section,summary,time,mark,audio,video,select{margin:0;padding:0;border:0;outline:none;line-height:1;}
a:focus,a:hover,a:active,button{outline:none;text-decoration:none;}
a{
   transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}
/*************************************************/
/*      1 ) Google or local Font Import          */
/*************************************************/
/* Imports File*/
html{height:100%;text-rendering:optimizeLegibility!important;-webkit-font-smoothing:antialiased !important;}
body {

    font-family: 'Gotham Rounded';
    font-weight:400;
    font-size:14px;
    height:100%;
}
.text_color1{color:#0a4996;}
.text_color2{color:#a15b1c;}
.text_color3{color:#262262;}
.text_color4{color:#FBAC1A;}
.sound_bubble_demoregular{font-family:'sound_bubble_demoregular';}
/**********************************/
/*      2 ) Helping Classes       */
/**********************************/
.clear{clear: both;}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
}
.table-content{display:table;width:100%;height:100vh;}
.table-cell{display:table-cell;vertical-align:middle;padding-top:70px;}
.noscroll{overflow:hidden;}
.container{max-width:1260px;width:100%;margin:0 auto;}
.table td, .table th{vertical-align:middle;display: revert !important;}
ul{list-style:none;}
/***********************************
            3 ) Header        
************************************/
/*.site-header {background:#262262 url('../img/dashboard-header-bg.png');background-size:cover !important;background-position:center !important;background-repeat:no-repeat !important;}*/
/*-------( a ) Logo------*/
/*header.site-header nav .navbar-brand img {max-width: 145px;}*/
/*-------( b ) Menu-------*/
/*header.site-header nav ul li{margin:0px 15px;}*/
/*header.site-header nav ul li a {color: #fff;transition: color 300ms 0s ease;padding:0px 0px 5px 0px !important;font-weight: 400;display:inline-block;}*/
/*header.site-header nav ul li a:hover*/
/*,header.site-header nav ul li a.active*/{color:#fbac18;}
/*header.site-header nav ul li .menu-button {font-size: 14px;padding: 7px 15px !important;background: #fbac18;border-color: transparent;color: #fff !important;border-radius: 4px;transition:0.3s all;}*/
/*header.site-header nav ul li .menu-button:hover{background:#32588b;}*/
/*header.site-header nav {display: flex;align-items: center;justify-content: space-between;}*/
/*.search-pod-form{max-width: 500px;
margin: 0 auto;
width: 100%;
padding-bottom: 35px;
padding-top: 20px;}
.search-pod-form input {padding: 12px 15px;background: #f9fafa;font-size: 1rem;border: unset;border-radius: 4px;color: #40484a;transition:0.3s all;width:100%;}
.search-pod-form input:focus{background:#fff;box-shadow:0 0 0px 2px #48a1d9;}
.search-pod-form button {font-size: 16px;border-radius: 4px;border: unset;background: #fbac18;color: #262262;padding: 14.5px 33px;transition:0.3s all;cursor:pointer;}
.search-pod-form button:hover{background:#a15b1c;color:#fff;}
header.site-header .dropdown .dropdown-toggle {
  outline: none;
  cursor: pointer;
}
header.site-header .dropdown .dropdown-toggle:after{display:none;}
header.site-header .dropdown .dropdown-menu {
  left: 0px;
  width: 120px;
  background: rgba(255, 255, 255, 0.9);
  max-width: unset;
  min-width: unset;
  padding: 5px 0px;
  margin-top: 4px;
}
header.site-header .dropdown .dropdown-menu a {
  color: #7b7b7b;
  padding: 4px 10px !important;
  font-weight: 400;
  font-size: 14px;
}*/
/**/
.clv-dasboard .main-sidebar {
  width: 230px;
  overflow: hidden;
  overflow-y: scroll;
  background: url('../img/sidebar-bg.jpg');
  background-repeat: no-repeat !important;
  background-size: cover;
  min-height: 100vh;
  position: fixed;
  left: 0;
  height: 100%;
  scrollbar-width: thin;
scrollbar-color: white transparent;
}
.clv-dasboard .main-sidebar.sticky {
  position: fixed;
  top: 0;
}
.clv-dasboard{display:flex;align-items:flex-start;}
.clv-dasboard .main-sidebar .site-logo {
  text-align:center;
  padding: 10px;
  display: block;
}
.clv-dasboard .main-sidebar .site-logo img {
  max-width: 75px;
}
.clv-dasboard .main-sidebar .main-menu {
  padding: 20px 0px 20px 25px;
  list-style: none;
}
.clv-dasboard .main-sidebar .main-menu + .btn {
  position: relative;
  border-radius: 50px !important;
  padding: 14px 25px;
  margin: 20px 10px;
}
.clv-dasboard .main-sidebar .main-menu + .btn span {
  position: absolute;
  top: -8px;
  background: #363ea0;
  padding: 3px 0px;
  font-size: 12px;
  display: block;
  text-align: center;
  width: 70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0px 0px 14px 0px #363ea0;
  border-radius: 20px;
}
.clv-dasboard .main-sidebar .main-menu li a {
  color: #fff;
  font-size: 1.3rem;
  padding: 12px 0px 12px 30px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  font-family: 'sound_bubble_demoregular';
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  line-height: 1;margin-bottom:10px;
}
.clv-dasboard .main-sidebar .main-menu li a img {
  max-width: 35px;
  margin-right: 15px;
}
.clv-dasboard .main-sidebar .main-menu li a:hover,
.clv-dasboard .main-sidebar .main-menu li a.active{color:#35399c;background:#fff;}
.clv-dasboard .main-sidebar .main-menu li a i {
  color: #919eab;
  font-size: 18px;
  margin-right: 5px;text-shadow:none;
}
/*dashboard-right-section*/

.dashboard-right-section {
  width: 100%;
  padding-left: 230px;
}
.dashboard-right-section input, .dashboard-right-section textarea, .dashboard-right-section select {
  /* background: #f9fafa; */
  /* border: unset; */
  padding: 10px 15px;
  /* box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2); */
  border: 1px solid #A4A4A4;
  border-radius: .25rem;
  outline: none;
  overflow: hidden;
  color: #4D4D4D;transition:0.3s all;
}
.dashboard-right-section input:hover, .dashboard-right-section textarea:hover, .dashboard-right-section select:hover,
.dashboard-right-section input:focus, .dashboard-right-section textarea:focus, .dashboard-right-section select:focus {
  border-color: #A4A4A4;
  box-shadow: 0px 0px 6px 0px #A4A4A4;
}
.pagination li a {
  color: #fbac18;
  outline: none !important;box-shadow:unset !important;
}
.pagination li.active a{background:#fbac18 !important;border-color:#fbac18 !important;}
.pagination li a:hover{color:#fbac18;}
/**/
.inner-header h3 {
  font-family: 'sound_bubble_demoregular';
  font-size: 2.5rem;
}
.table{width:100% !important;}
.inner-header a {
  display: inherit;
  background: #b23885;
  padding: 10px 15px;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
}
div#pod_list_wrapper .row:first-of-type {
    margin-bottom: 15px;
}
.btn-primary {
  background: #b23885 !important;
  border: unset !important;
  border-radius: 2px !important;
  font-size: 14px;
}
.btn-secondary {
  background: #262262 !important;
  border: unset !important;
  border-radius: 2px !important;
  font-size: 14px;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: inset 0 1px 0 0 #6774c8,0 1px 0 0 rgba(22,29,37,.05),0 0 0 0 transparent !important;
}
.btn-primary:hover{background:#262262 !important;}
.btn-success {
    background: linear-gradient(180deg,#28a745,#00731a) !important;
    border-color: #086d1f !important;
    border-radius: 2px !important;
    font-size: 14px;border:unset;
}
.btn-danger{background: linear-gradient(180deg,#f00000,#d20505) !important;
border-color: #ff1010 !important;
border-radius: 2px !important;
font-size: 14px;}
.tm-tag.tm-tag-info {
  background: #0a4996 !important;
  border: unset !important;
  color: #fff !important;
}
.tm-tag.tm-tag-info .tm-tag-remove {
  color: #fff;
  opacity: 1;
  line-height: 1;
  display: inline-block;
  top: -1px;
  position: relative;
}
.table {
  background: #fff;
  border-radius: 4px;
  box-shadow: var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));
  outline: .1rem solid transparent;
  border: unset !important;margin-bottom:10px !important;
}
.table thead th {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-weight: 400 !important;
  border: unset !important;
}
/*view-pod*/
.getting-started-btn {
  background: #b23885;
  color: #fff;
  font-size: 20px;
  font-weight: lighter;
  padding: 12px 25px;
  display: inline-block;
  border-radius: 12px;
  margin-top: 20px;
}
.getting-started-btn:hover{color:#fff;background:#35399c;}
.vpod-body .vpod-nav ul {
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0px 0px;
  margin-bottom: 20px;
}
.vpod-body .vpod-nav ul li a, .index-links li a {
  display: inline-block;
  color: #222;
  font-weight: 300;
  padding: 13px 17px;
  font-weight: lighter;
  border-radius: 3px;
  margin-right: 7px;
  font-size: 14px;
  background: #E5E5E5;
}
.vpod-body .vpod-nav ul li a.active,
.vpod-body .vpod-nav ul li a:hover,
.index-links li a.active,
.index-links li a:hover{background:#b23885;color:#fff;}
.allow-broadcast {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;background:#f4f6f8;
}
.allow-broadcast.hide{display:none;}
.allow-broadcast i {
  color: #b23885;
  font-size: 50px;
}
.allow-broadcast p {
  font-size: 18px;
}
.allow-broadcast.start{display:none;}
.allow-broadcast.start.show{display:block;}
.allow-broadcast.teacher {
  width: 100%;
  max-width: 100%;
}
.custom-control-label::before,
.custom-control-label::after {top:0;}
.submission-boxes .box {
    width: 33.33%;
}
.submission-boxes .box h1 {
    padding: 14px 20px;
    font-size: 18px;
    background: #ccc;
    border-right: 1px solid #ddd;
}
#view_submission .img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50px;
    display: flex;
    align-items: center;
}
#view_submission .img img{max-width:100%;}
.submission-boxes .box .content {
    padding: 20px;
}
#view_submission h4 {
    font-size: 18px;
}
#view_submission thead tr th:first-of-type {
  max-width: 250px;
  width: auto !important;
}
#view_submission tbody tr td:first-of-type {
  max-width: 250px;
  overflow: hidden;
  width: 220px;
}
#view_submission tbody tr td .img-box {
  width: 100%;
  max-width: 250px;
}
.img-box .img {
  width: 50px;height:50px;
  border-radius: 50px;
  overflow: hidden;
}
.card-body{padding:0px;}

#view_submission tbody tr td:first-of-type {
  max-width: 250px;
  overflow: hidden;
}

.board-box {
  margin:30px 0px;
}
.board-box .left ul {
  list-style: none;
}
.board-box .left {
  width: 100px;
  border-right: 1px solid #ccc;
}
.board-box .left a {
  display: block;
  padding: 15px 10px;
  font-size: 18px;
  text-align: center;
  color: #222;
}
.board-box .left a:hover{background:#ccc;}
div.dataTables_length label select,
div.dataTables_filter label input {
    margin: 5px 0px;
}
div.dataTables_length label {
    margin: 10px 0px 20px;
}
div.dataTables_length label .custom-select {
  padding-top: 8px !important;
}

/*editor*/
.editor-menu .inner {
  display: flex;
  align-items: center;
}
.editor-menu .inner a,
.editor-menu .editor-dropdown span.editor-dropdown-open {
  width: 40px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #d4d7db;
  border-radius: 2px;
  background: #f4f6f8;
  color: #b23885;cursor:pointer;
}
.editor-menu .inner a.current,
.editor-menu .editor-dropdown span.editor-dropdown-open.current {
  background: #aaa9a9;
  color: #fff;
}
.editor-menu .editor-dropdown .inner-menu {
  position: absolute;
  left: 0;
  z-index: 3245678980099765;
  background: #fff;
  border: 1px solid #b23885;
  width: 120px;
  border-radius: 2px;
  padding: 10px;
  font-size: 13px;display:none;
}
.editor-menu .editor-dropdown .inner-menu.show{display:block;}
.editor-menu .editor-dropdown .inner-menu a {
  display: block;
  color: #b23885;
  padding: 6px 5px;
}
.editor-menu .editor-dropdown .inner-menu a:last-of-type {
  text-align: center;
  padding-top: 5px;
}
.editor-menu .editor-dropdown .inner-menu a:hover,
.editor-menu .editor-dropdown .inner-menu a.current{background:rgba(0,0,0,0.1);}
.editor-menu .inner .color .current label:before{background-color: #007bff;}
.editor-menu .inner .color .current label:after{background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");}
.literally canvas:first-of-type {
  opacity: 0;
}
#attendence_wrapper .custom-control {
    margin-top: -11px;
}
/*view-submission*/
.view-submission .left {
  width: 65%;
}
.view-submission .right{width:30%}

.view-submission p{line-height:1.3;}
.view-submission .left .box-inner .date {
  width: 31%;
}
.add-file {
  overflow: hidden;
  border: 2px dashed #c1c1c1;
  transition: 0.3s all;
  cursor: pointer;
  height: 250px;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.nadd-file {
  height: 250px !important;
}
.add-file i {
  font-size: 35px;
  color: #595959;
  text-shadow: 0px 0px 7px #c1c1c1;
}
.add-file .btn.btn-light{
background-color: #dbdbdb;
border-color: #dbdbdb;}
.add-file img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
/*.add-file:hover {
  background: #ececec;
}*/
.add-file input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;cursor:pointer;
}
/*quiz*/
.question ul{list-style:none;}
.head-question span {
  font-size: 12px;
  margin-left: 10px;
  color: #707070;
  cursor: pointer;
  border: 3px solid #707070;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-question span.collapse-question::before {
  content: '\f068';
  font-family: 'FontAwesome';
  font-size: 14px;
}
.head-question span.collapse-question.active::before {
  content: '\f067';
}
#question.box .mcq,
#question.box.mcq .mcq{display:block;}
#question.box .true-false,
#question.box.mcq .true-false{display:none;}


#question.box.true_false .mcq{display:none;}
#question.box.true_false .true-false{display:block;}
#question.box .mcq .question-inner,
#question.box.true_false .true-false {
  background: #ECECEC;
  border-radius: 10px;
}
#question.box .mcq .question-inner input, #question.box .mcq .question-inner select,
#question.box.true_false .true-false input, #question.box.true_false .true-false select {
  background: transparent;
  color: ;
  color: #292929;
}
/*report*/
.chart-slider {
  padding-bottom: 50px;
}
.chart-slider .slick-prev,
.chart-slider .slick-next {
  position: absolute;
  right: 50px;
  bottom: 10px;
  top: unset;
  left: unset;
  z-index: 32145467;
  color: #000;
}
.chart-slider .slick-next {
  right: 20px;
}
.chart-slider .slick-prev::before,
.chart-slider .slick-next::before  {
  content: '\f104';
  font-family: 'FontAwesome';
  color: #000;
}
.chart-slider .slick-next::before{content: '\f105';}#monthLabel{
    font-size: 20px;
    font-family: arial;
    max-width: 710px;
    margin: 0px auto;
    background-color: #0288d1;
    color: white;
    font-weight: bold;
    padding:10px;
    text-align: center;
}
.report-total-student .account-student .box {
  transition: 0.3s all;
  width: 100%;
  max-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.report-total-student .account-student .box:hover {
  background: #b23885 !important;
  border-color: #b23885 !important;
  transform: scale(1.1);
  box-shadow: 0px 0px 4px 0px #b23885;
}
.report-total-student .account-student .box.female:hover{ background: #fbac18 !important;
  border-color: #fbac18 !important;
  box-shadow: 0px 0px 4px 0px #fbac18;}

  /***************************
      Monitoring alerts
  ****************************/
.card-body.monitoring-alert .img {
  max-width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.card-body.monitoring-alert .img img{max-width:100%;min-height:100%;}
.card-body.monitoring-alert .box {
  width: 24%;
}
.card-body.monitoring-alert .box h4 {
  font-size: 16px;
  margin: 8px 0px;
}
.social-distancing .box {
  background: #262262;
}
.detection-of-mask .box {
  background: #fbac18;
}
.temperature .box {
  background: #b23885;
}

/***************************
    Competition
***************************/
.competition-boxes .box {
  width: 32%;
  background: #262262;
  color: #fff;
  border-radius: 3px;padding-top:1px;margin-top:60px;
}
.competition-boxes .box .img {
  width: 80px;
  overflow: hidden;
  display: flex;
  height: 80px;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: -40px;
}
.competition-boxes .box .content {
  width: 100%;
  text-align:center;
}
.competition-boxes .box h2 {
  font-size: 20px;
  margin: 20px 0px 0px;
}
.competition-boxes .box .fees {
  margin: 8px 0px;
}
.competition-boxes .box .fees span {
  color: #fbac18;
  font-size: 24px;
}
.competition-boxes .box .last-date {
  margin-bottom: 6px;
}
.competition-boxes .box a {
  width: 100%;
  margin-top: 10px;
}
.winner-box {
  display: flex;
  align-items: center;
  background: #b23885;
  color: #fff;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
}
.winner-box .img {
  font-size: 110px;
  color: #fbac18;
  margin-right: 30px;
}
.winner-box .img i {
  font-size: 110px;
  color: #fbac18;
}
.winner-box .content {
  width: 80%;
}
.winner-box .content h2 {
  font-family: 'sound_bubble_demoregular';
  font-size: 34px;
  letter-spacing: 1px;
}
.winner-box .content p {
  font-size: 35px;
  color: #fbac18;
  font-weight: bold;
}

/******************************
    Teacher Dashhboard
******************************/
.allow-broadcast.teacher .pod-camera .img {
  width: 100%;
  height: 420px;
  overflow: hidden;
  display: flex;
  position: relative;
}
.allow-broadcast.teacher .pod-camera .img .min-camera {
  position: absolute;
  width: 100px;
  height: 70px;
  display: flex;
  bottom: 10px;
  left: 10px;
  border: 1px solid #fff;
  overflow: hidden;
}
.allow-broadcast.teacher .pod-camera i {
  font-size: unset;
}
.allow-broadcast.teacher .pod-camera .img ul {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.allow-broadcast.teacher .pod-camera .img ul li a {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0px 6px;
}
.allow-broadcast.teacher .pod-camera .img ul li a.disable {
  opacity: 0.7;
  position: relative;
}
.allow-broadcast.teacher .pod-camera .img ul li a.disable::before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  border: 1px solid #fff;
  transform: rotate(38deg);background:#fff;
}
.allow-broadcast.teacher .related-call ul {
  display: flex;
}
.allow-broadcast.teacher .related-call ul li a {
  display: inline-block;
  margin-right: 6px;
  margin-top: 5px;
}
.allow-broadcast.teacher .related-call ul li a i,
.collaborate-camera .inner ul li a {
  font-size: unset;
  color: #373737;
}
.allow-broadcast.teacher .related-call ul li a.disable i,
.collaborate-camera .inner ul li a.disable {
  color: #ccc;
}
.allow-broadcast.teacher .related-call ul li a.red i {
  color: #d9202f;
}
.allow-broadcast.teacher .related-call ul li a.green i {
  color: #4bcc0c;
}
.allow-broadcast.teacher .related-call ul li a.red.disable i,
.allow-broadcast.teacher .related-call ul li a.green.disable i {
  opacity: 0.3;
}
.allow-broadcast.teacher .related-call .img {
  border-radius: 2px;width:90px;height:90px;
}
.allow-broadcast.teacher .related-call {
  height: 420px;
  width: 100%;
  overflow-y: scroll;
}
.allow-broadcast.teacher .pod-camera .share-link ul{display:flex;align-items:center;}
.allow-broadcast.teacher .pod-camera .share-link ul li a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 20px;margin:0px 5px;
}
.allow-broadcast.teacher .pod-camera .share-link ul li a i {
  color: #fff;
}
.allow-broadcast.teacher .pod-camera .share-link ul li a.facebook{background:#3a5499;}
.allow-broadcast.teacher .pod-camera .share-link ul li a.whatsapp{background:#24cc63;}
.allow-broadcast.teacher .pod-camera .share-link ul li a.instagram{background:#cf2e82;}

/*chat-box*/
.chat-box .inner .left, .chat-box .inner .right {
  display: flex;
  align-items: start;margin-bottom:10px;
}
.chat-box .inner .left .img, .chat-box .inner .right .img {
  width: 50px;
  height: 50px;
  border-radius: 50px;overflow:hidden;
}
.chat-box .inner .left .content {
  padding-left: 10px;
}
.chat-box .inner .right .content{padding-right:10px;}
.chat-box .inner .left .content h2,
.chat-box .inner .right .content h2 {
  font-size: 14px;
  color: #6c6c6c;
  font-weight: lighter;
}
.chat-box .inner .left .content p,
.chat-box .inner .right .content p {
  background: rgba(0,0,0,0.7);
  padding: 4px;
  color: #fff;
  font-weight: lighter;
  font-size: 15px;
  margin-top: 6px;
  border-radius: 0px 4px 4px 4px;
  display: inline-block;
}
.chat-box .inner .right {
  justify-content: flex-end;
}
.chat-box .inner .right .content p {
  border-radius: 4px 0px 4px 4px;
}
.chat-box .inner {
  height: 330px;
  overflow-y: scroll;
}
.chat-box .form form {
  width: 100%;display:flex;align-items:center;
}
.chat-box .form form input {
  width: 92%;
  font-weight: lighter;
}
.chat-box .form form button {
  width: 66px;
  border: unset;
  height: 41px;
  font-size: 30px;
  margin: 0;
  padding: 0;background:#4c4c4c;cursor:pointer;
}
.chat-box .form form button i {
  color: #fff;
}
.collaborate-camera {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: -70px;
  position: relative;
  z-index: 123;
  padding: 0px 20px;
}
.collaborate-camera .inner {
  width: 200px;
}
.collaborate-camera .inner .img {
  width: 100%;
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collaborate-camera .inner ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.collaborate-camera .inner ul li a{margin:10px 5px 0px 5px;display:inline-block;}

/***************************
      chs-section
***************************/
.chs-section .left h1 {
  font-weight: bold;
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #DBDBDB;
}
.chs-section .left h3 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.chs-section .left .inrbox {
  padding: 0px 20px;
  border-right: 2px solid #DBDBDB;
  height: 100%;
}
.chs-posts {
  margin-top: 30px;
}
.chs-posts .box .title {
  display: flex;
  align-items: center;
}
.chs-posts .box .title .img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
  border: 2px solid #DBDBDB;
}
.chs-posts .box .title .name h2 {
  font-size: 18px;
  font-weight: 500;
  color: #111;
  margin-bottom: 5px;
}
.chs-posts .box .title .name span {
  display: inline-block;
  color: #707070;
  font-size: 13px;
}
.chs-posts .box p {
  color: #A4A4A4;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
  margin: 15px 0px;
}
.chs-posts .box .main-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DBDBDB;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.chs-posts .box .post-action .like {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chs-posts .box .post-action .like button {
  width: 50%;
  border: unset;
  background: #EBEBEB;
  font-size: 22px;
  padding: 18px 0px;outline:none;
  color: #717171;cursor:pointer;transition:0.2s all;
}
.chs-posts .box .post-action .like button:first-of-type {
  border-right: 1px solid #BFBFBF;
}
.chs-posts .box .post-action .like button:hover, .chs-posts .box .post-action .like button.active{color:#3a42a4;}
.chs-posts .box .post-action form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: 1px solid #DBDBDB;
}
.chs-posts .box .post-action input {
  width: 90%;
  background: transparent;
  border: unset;
  box-shadow: none;
  outline: none;font-size:16px;
}
.chs-posts .box .post-action button {
  width: 10%;
  border: unset;
  height: 100%;
  padding: 10px 15px;
  background: transparent;
  color: #717171;
  font-size: 20px;cursor:pointer;
}
.chs-posts .box {
  margin-top: 30px;
  padding-top: 25px;
  border-top: 2px solid #DBDBDB;
}
.chs-posts .box:first-of-type{border:none;padding:unset;margin:unset;}
.chs-posts {
  margin-top: 30px;
  min-height:500px;
  max-height: 100vh;
  overflow-y: scroll;
}
.chs-section .right .chsrbox {
  border: 1px solid #D9D9D9;
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px #ccc;
}
.chs-section .right .chsrbox h2 {
  color: #111111;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.chs-section .right .upload-post input {
  width: 100%;
  background: transparent;
  margin-bottom: 10px;
  outline: none;
}
.chs-section .right .upload-post button, .chs-section .right .rboxgroups .gbox a.view-all, .chs-posts .loadmore,
.chs-section .left .inrbox .currentgroup-name button {
  background: #FBAC18;
  border: unset;
  color: #fff;
  padding: 15px 24px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 15px;transition:0.3s all;
}
.chs-section .right .upload-post button:hover, .chs-section .right .rboxgroups .gbox a.view-all:hover, .chs-posts .loadmore:hover{background:#363ea0;}
.chs-section .right .rboxgroups{margin-top:20px;}
.chs-section .right .rboxgroups .gbox .inner{display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;padding: 10px 0px;}
.chs-section .right .rboxgroups .gbox .left {
  width: 90%;
  display: flex;
  align-items: center;
}
.chs-section .right .rboxgroups .gbox .left .img {
  width: 60px;
  height: 60px;
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}
.chs-section .right .rboxgroups .gbox .left .img img {
  width: 100%;
}
.chs-section .right .rboxgroups .gbox .left .title h2 {
  margin-bottom: 10px;
}
.chs-section .right .rboxgroups .gbox .left .title p {
  color: #A4A4A4;
  text-transform: uppercase;
  font-size: 16px;
}
.chs-section .right .rboxgroups .gbox .right {
  width: 10%;
  position: relative;
  text-align: right;
}
.chs-section .right .rboxgroups .gbox .right .acinner {
  position: absolute;
  right: 0;
  width: 90px;
  background: #ccc;
  padding: 3px 0px;transition:0.3s all;text-align:left;
  border-radius: 2px;opacity:0;visibility:hidden;
}
.chs-section .right .rboxgroups .gbox .right .acinner.show{opacity:1;visibility:visible;}
.chs-section .right .rboxgroups .gbox .right .acinner a {
  display: block;
  padding: 5px 10px;
  color: #fff;
}
.chs-section .right .rboxgroups .gbox .right .acinner a:hover{color:#fbac18;}
.chs-section .right .rboxgroups .gbox .right span {
  font-size: 22px;
  text-align: right;
  color: #A4A4A4;cursor:pointer;margin-right:5px;
}
.chs-section .right .rboxgroups .gbox a.view-all {
  display: inline-block;
}
.chs-section .left .inrbox .currentgroup-name {
  width: 100%;
  height: 258px;
  background-size: cover !important;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;justify-content:space-between;
}
.chs-section .left .inrbox .currentgroup-name::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(255,0,0,0), rgb(54, 62, 160));
}
.chs-section .left .inrbox .currentgroup-name button {
  margin: 0px 20px 30px 0px;
  position: relative;
  z-index: 123;
}
.chs-section .left .inrbox .currentgroup-name button.join {
  background: #ccc;
  color: #3b42a2;
}
.chs-section .left .inrbox .currentgroup-name .content {
  position: relative;
  z-index: 1234;
  padding: 0px 0px 30px 20px;width:65%;
}
.chs-section .left .inrbox .currentgroup-name .content h2 {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}
.chs-section .left .inrbox .currentgroup-name .content .group-member {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.chs-section .left .inrbox .currentgroup-name .content .group-member img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-left: -12px;border:1ox solid #ccc;
}
.chs-section .left .inrbox .currentgroup-name .content .group-member img:first-of-type{margin-left:0;}
.chs-section .left .inrbox .currentgroup-name .content .group-member span {
  color: #fff;
  font-size: 16px;
}
.chs-section .left .inrbox .currentgroup-name .content .group-member span.count.count {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: rgba(0,0,0,0.7);
  font-size: 14px;
  margin-left: -30px;
  margin-right: 10px;
}
.chs-section .left .inrbox .currentgroup-name .bgimg {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  top: 0;
  height: 258px;
}
.chs-section .left .inrbox .currentgroup-name .bgimg img {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.chs-posts .box .post-action .comments .cmboxes .cbox {
  display: flex;
  align-items: flex-start;margin:15px 0px;
}
.chs-posts .box .post-action .comments .cmboxes .cbox .img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chs-posts .box .post-action .comments .cmboxes .cbox .content {
  border-radius: 8px;
  margin-left: 10px;
  padding: 0;
}
.chs-posts .box .post-action .comments .cmboxes .cbox .content h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.chs-posts .box .post-action .comments .cmboxes .cbox .content p {
  color: #000;
  margin: 0;
  line-height: 1.3;
  margin-top: 6px;
  background: #f0d5a1;
  min-width: 200px;
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  font-family: 'arial';
}
.chs-posts .box .post-action .comments .cmboxes {
  padding: 10px 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}
.covid19-box {
  background: url('../img/covid.png');
}
.modelbtnaccept .modal-content {
  padding: 20px 20px;
  text-align: center;
}
.modelbtnaccept .modal-content h5 {
  color: #000;
  font-size: 24px;
  line-height: 1.3;
  font-weight: bold;
}
.modelbtnaccept .modal-content .modal-body button {
  border: 1px solid #262262;
  padding: 15px 30px;
  background: #262262;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;outline:none;
}
.modelbtnaccept .modal-content .modal-body button:last-of-type {
  background: transparent;
  color: #A4A4A4;
  border-color: #A4A4A4;
}
.modelbtnaccept .modal-content button.close {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #000;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 1px 6px;outline:none;
}
.modelbtnaccept .modal-content button.close span{color:#000;}
.modelbtnaccept .modal-content h5.bt2{display:none;}
.modelbtnaccept .modal-content h5.bt2.active{display:block;}
.modelbtnaccept .modal-content h5.bt1.active{display:none;}
.modelbtnaccept .modal-content .modal-body.active {
  display: none;
}
thead {
  background: #EFEFEF;
  border-color: #E2E2E2;
}
.dashboard-right-section .right.border{box-shadow: 0px 0px 28px 0px #e6e6e6;
border-radius: 15px}
.dviewbox{width: 49%;
background: #EFEFEF;
border-color: #E2E2E2;}
.next-btn {
  display: inline-block;
  background: #262262;
  font-family: 'sound_bubble_demoregular';
  color: #fff !important;
  font-size: 25px;
  padding: 14px 38px 10px;
  line-height: 1;
  border-radius: 100px;
  box-shadow: 0px 3px 0px 0px #B2B2B2;
  border-bottom: 3px solid #110F34;
  position: relative;overflow:hidden;
}
/*.next-btn::before {
  content: '';
  position: absolute;
  left: -10px;
  right: 0;
  top: -231px;
  border-radius: 110px;
  height: 249px;
  width: 117%;
  transform: rotate(0deg);
  background-image: linear-gradient(to bottom, #0000, #ffffff5e);
}*/

/***************************
      CLV Pagination
***************************/
.clv-pagination ul li a {
  background: #B53F89;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 16px;
  text-align: center;
  color: #fff;
  font-family: 'sound_bubble_demoregular';
  font-size: 32px;
  position: relative;margin:0px 5px;
}
.clv-pagination ul li a .icon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  left: 0;
  right: 0;
  color: #A4A4A4;
  justify-content: center;
  top: -10px;
}
.clv-pagination ul li a .icon i:first-of-type, .clv-pagination ul li a .icon i:last-of-type {
  font-size: 12px;
}
.clv-pagination ul li a.active .icon{color:#FBAC1A;}
.chs-main-page .chs-inner .boxes .slick-prev.slick-arrow {
  right: 80px;
  left: unset;
  top: -30px;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0px 0px 2px 0px #ccc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chs-main-page .chs-inner .boxes .slick-next.slick-arrow {
  right: 29px;
  top: -30px;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-shadow: 0px 0px 2px 0px #ccc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chs-main-page .chs-inner .boxes .slick-prev.slick-arrow::before,
.chs-main-page .chs-inner .boxes .slick-next.slick-arrow::before {
  content: '\f104';
  font-family: 'FontAwesome';
  color: #ccc;
  line-height: 1;
}
.chs-main-page .chs-inner .boxes .slick-next.slick-arrow::before{content: '\f105';}
.chs-main-page .chs-inner .boxes.noarrow .slick-arrow{opacity:0;visibility:hidden;}
.dashboard-profilebar {
  background: #3c3ca0;
  padding: 5px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;position: -webkit-sticky;
position: sticky;
top: 30px;
z-index: 12312312313;
}
.version-alert{position: -webkit-sticky;
position: sticky;
top: 0;
z-index: 12312312313;}
.dashboard-profilebar .dpinner{display: flex;
  align-items: center;}
.dashboard-profilebar .dpinner > a {
  color: #fff;
  font-size: 22px;
  display: inline-block;
  margin-right: 15px;
}
.dashboard-profilebar .dinner {
  display: inline-flex;
  align-items: center;
  background: rgba(0,0,0,0.3);
  padding: 3px 15px 3px 3px;
  border-radius: 50px;cursor:pointer;position:relative;
}
.dashboard-profilebar .dinner .dremoveClass {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 123426543543564;
  cursor: pointer;
  opacity: 0;
}
.dashboard-profilebar .dinner .img {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
}
.dashboard-profilebar .dinner .img img{width:100%;}
.dashboard-profilebar .dinner h2 {
  color: #fff;
  font-size: 16px;
  margin-left: 5px;
}
.dashboard-profilebar .dinnerBox {
  width: 300px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 80px;
  padding: 12px;
  background: rgba(50, 54, 154, 0.9);
  border-radius: 5px;transition:0.3s all;
  z-index: 123123;opacity:0;visibility:hidden;
}
.dashboard-profilebar .dinner .dinnerBox.active{top:46px;opacity:1;visibility:visible;}
.dashboard-profilebar .dinnerBox .dinnerProfile {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
  background: rgba(52, 54, 100, 0.9);
}
.dashboard-profilebar .dinnerBox .dinnerProfile .img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  border-radius: 100%;
}
.dashboard-profilebar .dinnerBox .dinnerProfile .img img{width:100%;}
.dashboard-profilebar .dinnerBox .dinnerProfile .content {
  width: 62%;
  overflow: hidden;
  text-align: left;
  padding: 0px 0px 0px 10px;
}
.dashboard-profilebar .dinnerBox .dinnerProfile .content h2 {
  color: #fff;
  font-size: 18px;
}
.dashboard-profilebar .dinnerBox .dinnerProfile .content p {
  color: #fff;
  font-size: 13px;
  margin-top: 5px;
  font-family: 'arial';
}
.dashboard-profilebar .dinnerBox ul li a {
  display: block;
  text-align: left;
  padding: 12px 14px;
  background: rgba(52, 54, 100, 0.9);
  margin: 5px 0px;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
}
.dashboard-profilebar .dinnerBox ul li a:hover{opacity:0.7;}
.dashboard-profilebar .open-menu {
  width: 35px;cursor:pointer;display:none;position:relative;
}
.dashboard-profilebar .open-menu .opm {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;z-index:1233;
}
.dashboard-profilebar .open-menu span {
  width: 100%;
  height: 2px;
  background: #fff;
  display: block;
  margin-bottom: 6px;transition:0.5s all;position:relative;
}
.dashboard-profilebar .open-menu.active span:first-of-type {
    transform: rotate(46deg);
    top: 6.5px;
    left: 3px;
}
.dashboard-profilebar .open-menu.active span:nth-child(3) {
    opacity: 0;
}
.dashboard-profilebar .open-menu.active span:last-of-type {
    transform: rotate(-47deg);
    top: -9px;
    left: 3px;
}

input[type="date"] {
    position: relative;overflow:hidden;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 100px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.view-submission .add-file {
  height: 78px;
}