@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/* You can add global styles to this file, and also import other style files */
/*
--------------------------------------------------------* 
[Main Stylesheet ]

    Name         : 

    Version      :  1.0

    Author       :  Abdullah
--------------------------------------------------------
   TABLE OF CONTENTS:
========================================================
1 ) Google or local Font Import
2 ) Helping Classes
3 ) Header 
        -( a ) Logo
        -( b ) Menu
4 ) Home page
        -( a ) Story of Glory
        -( b ) What Can I Do
        -( c ) Some Facts
5 ) About Page
        -( a ) Education Section
        -( b ) Experience Section
        -( c ) Skills Section
6 ) Single Page
        -( a ) Portfolio Section
        -( b ) Testimonial Section
7 ) Contact Page
        -( a )  Contact Form
        -( b )  Contact Details
8 ) Footer
--------------------------------------------------------*/
/*      Reset CSS                 */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,
label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,
hgroup,menu,nav,output,section,summary,time,mark,audio,video,select{margin:0;padding:0;border:0;outline:none;line-height:1;}
a:focus,a:hover,a:active,button{outline:none;text-decoration:none;}
a{
   transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}
a{text-decoration:none !important;}
/*************************************************/
/*      1 ) Google or local Font Import          */
/*************************************************/
/* Imports File*/
html{height:100%;text-rendering:optimizeLegibility!important;-webkit-font-smoothing:antialiased !important;}
body {

    font-family: 'Gotham Rounded' !important;
    font-weight:400;
    font-size:14px;
    height:100%;
}
.text_color1{color:#32678a;}
.text_color2{color:#fbac18;}
.clv-btn-shadow{box-shadow: 0px 3px 0px 0px rgba(0,0,0,0.2);}
.font_size1{font-size:1rem;}
.font_size2{font-size:1.3rem;}
.font_family_sound_bubble{font-family: 'sound_bubble_demoregular';}
.clv_bg{background:#b23885;}
/**********************************/
/*      2 ) Helping Classes       */
/**********************************/
.clear{clear: both;}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
}
.table-content{display:table;width:100%;height:100vh;}
.table-cell{display:table-cell;vertical-align:middle;padding-top:70px;}
.noscroll{overflow:hidden;}
.container{max-width:1260px;width:100%;margin:0 auto;}
ul{list-style:none;}

/***********************************
            4 ) Home Page        
************************************/

.services-section {border-top: 50px solid #fbac18;}
/*teacher Section*/
.teacher-section {background: url('assets/img/online-learning.jpg');display: block;background-size: cover;background-position: bottom;height:380px;}
.teacher-section .container,
.teacher-section .container .row {position: relative;height:100%;}
.teacher-section .container .row h2 {font-weight: 700;line-height: 1.2;letter-spacing: -.02rem;font-size: 2.2rem;margin-bottom: 10px;color: #3c3b37;}
.teacher-section .container .row p {font-size: 14px;line-height: 1.3;margin: 20px 0px 10px;color: #fff;font-weight: 400;}
.teacher-section .container .row a {padding: 14px 45px;color: #1c1c1c;font-weight: 300;font-size: 20px;}


/*home-services-section*/
.home-services-section .container .row .img {width: 100%;height: 100px;display: flex;align-items: center;justify-content: center;}
.home-services-section .container .row h2 {color: #b23885;font-weight: bold;font-size: 1.3rem;margin-bottom:15px;}
.home-services-section .container .row p {font-weight: 300;line-height:1.3;}
/*home-featured-courses*/
.home-featured-courses{background:url('assets/img/featured-courses-bg.jpg');background-repeat:no-repeat !important;background-size:cover !important;}
.home-featured-courses .boxes .box .content{color:#fff;}
.home-featured-courses .boxes .box .img{height:150px;}
.home-featured-courses .boxes .box .img div {height: 130px;}
.home-featured-courses .boxes .box .content h2{font-size:1.5rem;}
.home-featured-courses .boxes .slick-prev,
.home-featured-courses .boxes .slick-next {height: auto;width: auto;z-index:132456;}
.home-featured-courses .boxes .slick-prev {left: -30px;}
.home-featured-courses .boxes .slick-next {right: -30px;}
.home-featured-courses .boxes .slick-prev::before,
.home-featured-courses .boxes .slick-next::before {content: '\f104';font-family: 'FontAwesome';font-size: 90px;font-weight: bold;}
.home-featured-courses .boxes .slick-next::before{content: '\f105';}
/*home-about-section*/
.home-about-section h1{color:#19425b;line-height:1.2;}
.home-about-section p{line-height:1.3;}
.home-about-section a {background: #19425b;color: #fff;text-transform: uppercase;padding: 18px 60px;}
/*home-testimonial*/
.home-testimonial {background: #373737;}
.home-testimonial .boxes{opacity:1 !important;transform:none !important;}
.home-testimonial .boxes .box {background: #fff;padding: 50px;border-radius: 15px;}
.home-testimonial .boxes .box .img {width: 100px;height: 100px;overflow: hidden;border-radius: 50px;display: flex;margin: 0 auto;border: 2px solid #fff;margin-top: -100px;margin-bottom: 15px;}
.home-testimonial .boxes .box h2 {color: #19425b;font-weight: bold;font-size: 1.3rem;margin-bottom:5px;}
.home-testimonial .boxes .box span{display:inline-block;font-weight:lighter;font-style:italic;}
.home-testimonial .boxes .box p {font-weight: 300;margin-top: 30px;line-height:1.3;}
.home-testimonial .testimonial-inner {padding: 50px 150px;}
.home-testimonial .boxes .slick-prev,
.home-testimonial .boxes .slick-next{height:auto;width:auto;}
.home-testimonial .boxes .slick-prev::before,
.home-testimonial .boxes .slick-next::before {content: '\f104';font-family: 'FontAwesome';font-size: 90px;}
.home-testimonial .boxes .slick-prev {left: 5%;}
.home-testimonial .boxes .slick-next {right: 5%;}
.home-testimonial .boxes .slick-next::before{content: '\f105';}
.home-testimonial .boxes .slick-dots li button::before {font-size: 60px;color: #fff;}

/*************************
    Sign Up page
*************************/
.signup-page{padding:12% 0px 50px;background: url('assets/img/banner-background.jpg');
  background-size: cover;}
.signup-page .inner {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
}
.signup-page .inner h2, .cpod-title {
  border-bottom: solid 1px #fff;
  color: #fff;
  display: block;
  font-weight: 700;
  font-size: 2.4rem;
  padding: 20px 0px 20px 0px;
  margin-bottom: 20px;
  font-family: 'sound_bubble_demoregular';
}
.signup-page .inner button.facebook {
  background: #1a538a;
  color: #fff;border:unset;box-shadow:unset;
}
.signup-page .inner button.facebook:hover{background:#164675;}
.signup-page .inner button {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  background: #fbac18;
  outline: unset;
  border: unset;
  box-shadow:unset;
}
.signup-page .inner button:hover,
.signup-page .inner button:focus{background:#a15b1c;}
.signup-page .inner button i,
.signup-page .inner button img{font-size:20px;margin-right:10px;}
.signup-page .inner form input, .signup-page.parent-signup .inner form select, .signup-page.parent-signup .inner form textarea {
  border-radius: 5px;
  color: #40484a;
  font-size: 1rem;
  height: auto;
  padding: 14px 45px 14px 40px;
  border: unset;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 400;transition:0.3s all;
}

.signup-page.parent-signup .inner form select{cursor:pointer;}
.signup-page .inner form input:focus,
.signup-page.parent-signup .inner form select:focus,
.signup-page.parent-signup .inner form textarea:focus{box-shadow: 0 0 0px 2px #48a1d9;}

.signup-page .inner form .input{position:relative;}
.signup-page .inner form .input::before {
  content: '\f0e0';
  font-family: 'FontAwesome';
  position: absolute;
  top: 15px;
  left: 14px;
  font-size: 16px;
  color: #cacbcc;
}
.signup-page.parent-signup .inner form select,
.create-pod .select select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
.signup-page.parent-signup .inner form select::-ms-expand,
.create-pod .select select::-ms-expand {
  display: none;
}
.create-pod .select select {
  width: 200px;cursor:pointer;
}
.create-pod .select::before {
  right: 10px;
}
.signup-page .inner form .input.password::before{content:'\f023';}
.signup-page .inner form .input.name::before{content:'\f007';}
.signup-page.parent-signup .inner form .input.country:before{content:'\f0ac';}
.signup-page.parent-signup .inner form .input.city:before{content:'\f1ad';}
.signup-page.parent-signup .inner form .input.u_state:before{content:'\f279';}
.signup-page.parent-signup .inner form .input.address:before{content:'\f124';}
.signup-page.parent-signup .inner form .input.zip_code:before{content:'\f1c6';}
.signup-page.parent-signup .inner form .input.language:before{content:'\f1ab';}
.signup-page.parent-signup .inner form .input.p_status:before{content:'\f22d';}
.signup-page.parent-signup .inner form .input.caregiver:before{content:'\f184';}
.signup-page.parent-signup .inner form .input.p_learning:before{content:'\f02d';}
.signup-page.parent-signup .inner form .input.level:before{content:'\f19d';}
.signup-page.parent-signup .inner form .input.question:before{content:'\f29c';}
.signup-page.parent-signup .inner form .input.dob:before{content:'\f1fd';}
.signup-page.parent-signup .inner form .input.cv:before{content:'\f15b';bottom:31px;top:unset;}
.signup-page.parent-signup .inner form .input.image:before{display:none;}
.signup-page .inner button.login,
.signup-page .inner a.login {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fbac18;
  box-shadow: unset;
  border: unset;
  color: #fff;
  font-size: 18px;
}
.signup-page.parent-signup .inner form textarea{white-space:unset;min-height:150px;}
.signup-page .inner button.login:hover,
.signup-page .inner a.login:hover{background:#a15b1c;}
.signup-page .inner p {
  color: #fff;
  font-family: 'arial';
}
.signup-page .inner p a{color:#fbac18;}
.signup-page .inner .add-student.btn {
  background: #fbac18;
  border: unset;
}
.signup-page .inner .add-student.btn:hover{background:#a15b1c;color:#fff;}
/***********************************
            Pod Single
***********************************/
.pod-page .banner-section {
  height: 400px;
  overflow: hidden;
  padding-top: 0 !important;padding-bottom:0px !important;
}
.pod-page .banner-section iframe {
  left: 0;
  top: 0;
  bottom: 0;
}
.pod-page .banner-section .inner {
  background: rgba(178,55,133,0.7);
  height: 100%;
}
.pod-page .banner-section .inner h4 {
  font-family: 'sound_bubble_demoregular';
  font-size: 2.4rem;
}
.pod-page .banner-section .inner .pod-img .img,
.services-section #students .student-list .inner .img,
.review-box .img,
.sugested-teacher .box .img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 50px;
}
.pod-page .banner-section .inner .pod-img .img img,
.services-section #students .student-list .inner .img img,
.sugested-teacher .box .img img{max-width:100%;}
.services-section #about p {
  font-size: 14px;
  color: #222;
  line-height: 1.3;
  font-weight: 300;
}
.tags-section a {
  background: #ccc;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
  color: #555;
}
.services-section #students .student-list .inner,
.sugested-teacher .box{
box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
border: 1px solid #eff2f5;width:48%;
}
.services-section #students .student-list .inner .img,
.sugested-teacher .box .img {
  border-radius: 4px;
  width: 70px;
  height: 70px;
}
.services-section #students .student-list .inner .content p,
.sugested-teacher .box .content p {
  font-size: 14px;
  font-family: 'Gotham Rounded Light';
}
.services-section #students .student-list .inner .content span,
.sugested-teacher .box .content span {
  font-size: 12px;
  color: #6d6e70;
  font-family: 'Gotham Rounded Light';
}
.services-section #students .student-list .inner .right a,
.sugested-teacher .box .right a {
  display: inline-block;
  background: #fbac18;
  color: #fff;
  padding: 10px 10px;
  border-radius: 3px;
  overflow: hidden;
}
.services-section #students .student-list .inner .right a:hover,
.sugested-teacher .box .right a:hover{background:#a15b1c;}
.join-pod {
  margin-bottom: 20px;
  
}
.join-pod .inner{
  background: url('assets/img/banner-background.jpg');
  background-size: cover !important;
  background-position: center !important;
}
.join-pod a {
  padding: 10px 20px;
  background: #fbac18;
  color: #fff;
  font-size: 17px;
  border-radius: 4px;
}
.join-pod a:hover{background:#a15b1c;color:#fff;}
.join-pod h2 {
  font-family: 'sound_bubble_demoregular';
  font-size: 3rem;
}
.review-box .left span {
  color: #8c8c8c;
  font-family: 'Gotham Rounded Light';
}
.review-box .right ul li {
  list-style: none;
  color: #eb8a2f;
  font-size: 18px;
  margin-left: 5px;
}
.review-box .right ul li.gray{color:gray;}
.review-box p {
  font-size: 14px;
  color: #222;
  line-height: 1.3;
  font-weight: 300;
  margin-top: 15px;
}
.review-box h5 a{color: #222;}


/**********************************
        Join Pod Page
***********************************/
.join-pod-page .signup-page.hide,
.join-pod-page .proceed-notification.hide,
.join-pod-page .create-pod.hide{display:none;}
.join-pod-page .questions .content {
  width: 74%;
}
.questions {
  color: #fff;
}
.proceed-notification .inner,
.create-pod .inner {
  max-width: 500px;width:100%;
  margin: 0 auto;
}
.proceed-notification .inner i{font-size:40px;}
.select-lr select {
  max-width: 200px;
}
.create-pod form button {
  margin: 0 auto;
  display: block;
  padding: 8px 50px;
  font-size: 18px;
}
.create-pod label, .create-pod h6 {
  color: #fff;
}
.create-pod .tm-input.form-control.tm-input-info {
  padding: .5rem;
}


.tm-tag.tm-tag-info {
  background: #0a4996 !important;
  border: unset !important;
  color: #fff !important;
}
.tm-tag.tm-tag-info .tm-tag-remove {
  color: #fff;
  opacity: 1;
  line-height: 1;
  display: inline-block;
  top: -1px;
  position: relative;
}

/************************************
        Parent Sign Up
*************************************/
.signup-page.parent-signup .inner {
  max-width: 800px;
}
.signup-page.parent-signup .inner form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.signup-page.parent-signup .inner form .input {
  width: 48%;
}
.parent-first-step.hide{display:none !important;}
.parent-second-step.hide{display:none !important;}
.parent-third-step.hide{display:none !important;}


.avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
}
div#parent_signup_image,
div#child_student_signup_image,
div#teacher_signup_image,
div#caregiver_signup_image {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
}
.avatar-upload{display:none;}
.avatar-upload .avatar-edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
  z-index: 1223456;
}
.avatar-upload .avatar-edit input {
  width: 100px !important;
  height: 100px !important;
  cursor: pointer;
}
.avatar-upload::before {
  content: '\f044';
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
  background: rgba(0,0,0,0.4);
  color: #fff;
  font-family: 'FontAwesome';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 4px;
  pointer-events: none;opacity:0;visibility:hidden;transition:0.3s all;
}
.avatar-upload:hover::before{opacity:1;visibility:visible;}
.signup-page.parent-signup .inner form .input.select::after {
  content: '\f107';
  font-family: 'FontAwesome';
  position: absolute;
  right: 16px;
  top: 10px;
  font-size: 25px;
  pointer-events: none;
}
.first-back, .second-back {
  color: #b23885;
  font-size: 18px;
  background: #fff;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 3px;
}
.first-back:hover,
.second-back:hover{color:#fbac18;}
.signup-page.parent-signup .inner h2 {
  text-align: center;
}
.signup-page.parent-signup h6 {
  color: #fff;
}
.signup-page.parent-signup .questions {
  border-color: #fff !important;
}
.custom-control-label::before{background:#fff;}
.signup-page.parent-signup .questions label span {
  color: #fff;
}
.parent-signup-avatar > img {
  width: 100px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.parent-signup-avatar .inner {
  padding: 0;
  max-width: unset !important;
}
.parent-signup-avatar .inner img {
  width: 40px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 8px;height:40px;
}
.signup-page.parent-signup .inner form .head-student {
  border-radius: 4px 4px 0px 0px;
  background: #17427c !important;
}
.signup-page.parent-signup .inner form .head-student span {
  display: inline-block;
  font-size: 22px;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;
}
.signup-page.parent-signup .inner form .head-student span.collapse-student::before {
  content: '\f068';
  font-family: 'FontAwesome';
  font-size: 20px;
}
.signup-page.parent-signup .inner form .head-student span.collapse-student.active::before{content: '\f067';}
.student-inner {
  background: rgba(23, 66, 124, 0.5);display:flex;
}
.pod-bottom-section .first .box {
  width: 100%;
  max-width: 20%;
}

/*********************************
      Teacher Page
*********************************/
.teacher-page .teacher-top-sectionn {
  background: #b23885 url('assets/img/banner-img.png');
  padding: 5% 0px 30px 0px;
}
.teacher-page .teacher-top-sectionn .inner {
  position: relative;
  top: 80px;
}
.teacher-page .teacher-top-sectionn .inner .img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  overflow: hidden;display:flex;
}
.teacher-page .teacher-top-sectionn .inner .right a.link {
  background: #fff;
  color: #373737;
  padding: 16px 25px;
  border-radius: 50px;
  text-transform: uppercase;
  border: 1px solid #ccc;
  font-size: 16px;
}
.teacher-page .teacher-top-sectionn .inner .right a.link:hover{background:#fbac18;color:#fff;border-color:#fbac18;}
.teacher-page .teacher-bottom-section .container .left {
  width: 62%;
}
.teacher-page .teacher-bottom-section .container .right {
  width: 32%;
}
.teacher-page .teacher-bottom-section .container .right .btn-primary {
  background: #b23885 !important;
  border: unset;
  border-radius: 2px !important;
  font-size: 14px;
}
.teacher-page .teacher-bottom-section .container .right .btn-success {
  background: linear-gradient(180deg,#28a745,#00731a) !important;
  border-color: #086d1f !important;
  border-radius: 2px !important;
  font-size: 14px;
}
/******************************
    Progress Bar
******************************/
.progress {
position: fixed;
right: 10px;
top: 115px;
height: 100px;
width: 100px;
background: #fff;
}

.progress .percent {
text-align: center;
font-size: 24px;
font-weight: bold;
color: rgba(0, 0, 0, 0.1);
position: absolute;
line-height: 100px;
width: 100%;
}

.progress svg path { fill: none; }

.progress svg.progress-circle path {
stroke: rgba(255, 0, 0, 0.55);
stroke-width: 2;
}
.onepage-wrapper .section{padding-top:11%;}
section.active{opacity:1;}
.errors {
  background: #fff;
  border-radius: 3px;
}
.errors li {
  padding: 5px 9px;
  color: red;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.errors li::before {
  content: '\f111';
  font-family: 'FontAwesome';
  font-size: 8px;
  margin-right: 7px;
}
/*.select_state option{display:none;}
.select_state option.active{display:inline-block;}*/
.parent-image {
    width: 200px;
    display: block;
    height: 200px;
    border: 2px dashed #fff;
    background: rgb(255 255 255 / 30%);
    cursor: pointer;
    position: relative;
}
.parent-image input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100% !important;
    margin: 0 !important;
    opacity: 0;cursor:pointer;
    outline: none;
}
.parent-image img {
    width: 100%;
    height: 100%;
}
.parent-image:before {
    content: '\f0ee';
    font-family: 'FontAwesome';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #595959;
}
.loading-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 123123123123123;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 80%);
}
.loading-overlay img {
    max-width: 350px;
}
ul.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
ul.tags li {
  background: #313599;
  color: #fff;
  padding: 7px 11px;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
}
